<template>
  <el-card>
    <el-form class="global-form2" ref="form" :model="formData" :rules="rules" label-width="140px" label-position="right">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="任务信息" name="1">
          <el-form-item label="任务名称" prop="planName">
            <el-input v-model="formData.planName" placeholder="请输入任务名称" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label="巡查方" prop="patrolUnit">
            <el-radio v-for="(item, index) in patrolUnitList" v-model="formData.patrolUnit" :label="index + 1" border :key="index"  @change="patrolUnitChange">{{item}}</el-radio>
          </el-form-item>
          <el-form-item label="所属项目" prop="projectId">
            <el-select v-model="formData.projectId" placeholder="请选择所属项目" @change="projectChange" filterable>
              <el-option v-for="(item, index) in projectList" :label="item.projectName" :value="item.projectId" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理类型" prop="safeManageId" :rules="[
          { required: true, message: '请选择管理类型', trigger: 'change' }
        ]">
            <el-select v-model="formData.safeManageId" @change="safeManageChange" filterable placeholder="请选择管理类型">
              <el-option v-for="(item, index) in safeManageList" :label="item.safeManageName" :value="item.safeManageId" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务执行人" prop="executeUserId">
            <el-select
              v-model="formData.executeUserId"
              placeholder="请选择任务执行人"
              :disabled="formData.projectId === '' || formData.safeManageId === ''"
              filterable
              clearable >
              <el-option v-for="(item, index) in userList" :label="item.userName" :value="item.userId" :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="任务配置" name="2">
          <el-form-item label="巡查形式" prop="patrolMode">
            <el-radio v-for="(item, index) in patrolModeList" v-model="formData.patrolMode" @change="patrolModeChange" :label="index + 1" border :key="index">{{item}}</el-radio>
          </el-form-item>
          <template v-if="formData.patrolMode === 1">
            <el-form-item label="巡查线路" prop="lineList" :rules="[
           { required: true, message: '请绘制巡查线路', trigger: ['change'] }
         ]">
            <el-button type="primary" @click="onLineSelect">{{formData.lineList && formData.lineList.length ? '已绘制 ' : '绘制巡查路线'}}</el-button>
          </el-form-item>
            <el-form-item label="巡查对象" prop="elementIds" :rules="[
           { required: true, message: '请选择巡查对象', trigger: ['change'] }
         ]">
            <el-button type="primary" @click="onObjSelect">{{formData.elementIds && formData.elementIds.length ? '已选 ' + formData.elementIds.length + ' 个' : '选择巡查对象'}}</el-button>
            </el-form-item>
            <div class="selected-items mb20" v-if="formData.elementIds.length">
                <div class="label">已选择巡查对象({{formData.elementIds.length}})</div>
                <div class="items">
                    <el-tooltip popper-class="tool-tip"  class="item" effect="dark" :content="item.standard || '暂无要素'" placement="top" v-for="(item, index) in checkElementList" :key="index">
                        <el-tag>{{item.elementName}}</el-tag>
                    </el-tooltip>
                </div>
            </div>
          </template>
          <template v-else>
            <el-form-item label="点位" prop="facilityIds" :rules="[
           { required: true, message: '请选择点位', trigger: ['change'] }
         ]">
            <el-button type="primary" @click="onPointSelect">{{formData.facilityIds && formData.facilityIds.length ? '已选 ' + formData.facilityIds.length + ' 个' : '选择巡查对象'}}</el-button>
          </el-form-item>
          <div class="selected-items mb20" v-if="formData.facilityIds.length">
                <div class="label">已选择巡查对象({{formData.facilityIds.length}})</div>
                <div class="items">
                    <el-tooltip class="item" effect="dark" popper-class="tool-tip" placement="top" v-for="(item, index) in checkFacilityList" :key="index" :content="item.elementContent || '暂无要素'">
                        <el-tag>{{item.facilityTypeName + "（" + item.count + "）"}}</el-tag>
                    </el-tooltip>
                </div>
            </div>
          </template>
          <el-form-item label="巡查类型" prop="planType">
            <el-radio v-for="(item, index) in planTypeList" @change="planTypeChange" v-model="formData.planType" :label="index + 1" border :key="index">{{item}}</el-radio>
          </el-form-item>
          <el-form-item label="巡查频次" prop="patrolFrequency">
            <el-input-number :step="1" step-strictly v-model="formData.patrolFrequency" controls-position="right" :min="1" :max="31" @change="frequencyChange" placeholder="请输入巡查频次"></el-input-number>&nbsp;次/{{dateName}}
          </el-form-item>
          <el-form-item label="巡查时段" prop="timeList" :rules="{required: true, type: 'array', message: '请选择巡查时间段', trigger: ['blur', 'change']}">
            <div class="timeList" v-for="(time, index) of formData.timeList" :key="index">
              <el-form-item
                :prop="`timeList.${index}.startTime`"
                :rules="{required: true, message: '请选择开始时间', trigger: ['change']}"
                :key="'start' + index">
                <el-time-select
                  v-model="time.startTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="{
                  start: index !== 0 ? formData.timeList[index - 1].endTime || '00:00' : '00:00',
                  end: formData.timeList[index].endTime || '24:00',
                  step: '00:15'
                }"
                  placeholder="开始时间">
                </el-time-select>&nbsp;&nbsp;至&nbsp;&nbsp;
              </el-form-item>
              <el-form-item
                :prop="`timeList.${index}.endTime`"
                :rules="{required: true, message: '请选择结束时间', trigger: ['change']}"
                :key="'end' + index">
                <el-time-select
                  v-model="time.endTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="{
                  start: formData.timeList[index].startTime || '00:00',
                  end: index < formData.timeList.length - 1 ? formData.timeList[index + 1].startTime || '24:00' : '24:00',
                  step: '00:15',
                  minTime: formData.timeList[index].startTime || '00:00'
                }"
                  placeholder="结束时间">
                </el-time-select>
              </el-form-item>
            </div>
          </el-form-item>
          <!-- <template v-else>
            <el-form-item label="巡查频次" prop="patrolFrequency">
              <el-input-number :step="1" step-strictly v-model="formData.patrolFrequency" controls-position="right" :min="1" :max="31" placeholder="请输入巡查频次"></el-input-number>&nbsp;次/{{dateName}}
            </el-form-item>
          </template> -->
          <el-form-item label="允许距离偏差" prop="offsetDistance">
            <el-input-number v-model="formData.offsetDistance" controls-position="right" :min="20" :max="100000" placeholder="请输入允许距离偏差"></el-input-number>&nbsp;米
          </el-form-item>
          <el-form-item label="生效方式" prop="generateType">
            <el-radio v-model="formData.generateType" :label="1" border>次{{dateName}}生效</el-radio>
            <el-radio v-model="formData.generateType" :label="2" border>立即生效</el-radio>
          </el-form-item>
          <el-form-item label="自动禁用日期" prop="planeStopTime">
            <el-date-picker v-model="formData.planeStopTime" :picker-options="pickerOptions" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择自动禁用日期"></el-date-picker>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <el-form-item class="mt20">
        <el-button type="primary" @click="submit" :disabled="disabled">确定</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <template v-if="formData.patrolMode === 1">
        <line-select
        :selectLineShow.sync="selectLineShow"
        :projectId="formData.projectId"
        :elementIds="formData.elementIds"
        :lineList="formData.lineList"
        :planeRegisterList="formData.planeRegisterList"
        @setLineList="setLineList">
        </line-select>
    <obj-select :selectObjShow.sync="selectObjShow" :elementIds="formData.elementIds" :patrolUnit="patrolUnit" @setElementIds="setElementIds"></obj-select>
    </template>
    <point-select
      v-else
      :selectPointShow.sync="selectPointShow"
      :projectId="formData.projectId"
      :patrolUnit="patrolUnit"
      :facilityIds="formData.facilityIds"
      @setFacilityIds="setFacilityIds">
    </point-select>
  </el-card>
</template>

<script>
import LineSelect from './LineSelect'
import ObjSelect from './ObjSelect'
import PointSelect from './PointSelect'
export default {
  name: 'PlanAdd',
  components: { LineSelect, PointSelect, ObjSelect },
  data () {
    return {
      patrolUnit: 1, // 1 管理抽查，2 项目自查
      activeNames: ['1', '2'],
      patrolModeList: ['线路', '点位'],
      planTypeList: ['日巡查', '周巡查', '月巡查'],
      patrolUnitList: ['管理抽查', '项目自查'],
      sectionList: [],
      safeManageList: [],
      userList: [],
      checkElementList: [],
      checkFacilityList: [],
      facilityNames: [],
      selectLineShow: false,
      selectObjShow: false,
      selectPointShow: false,
      disabled: false,
      formData: {
        planName: '',
        projectId: '',
        safeManageId: '',
        executeUserId: '',
        patrolUnit: 1,
        planType: 1,
        patrolFrequency: 1,
        timeList: [{
          startTime: '',
          endTime: ''
        }],
        planeRegisterList: [],
        patrolMode: 1,
        elementIds: [],
        lineList: [],
        facilityIds: [],
        offsetDistance: '50',
        generateType: 1,
        planeStopTime: ''
      },
      rules: {
        planName: [
          { required: true, message: '请输入任务名称', trigger: ['blur', 'change'] }
        ],
        patrolUnit: [
          { required: true, message: '请选择巡查方', trigger: ['change'] }
        ],
        projectId: [
          { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
        ],
        executeUserId: [
          { required: true, message: '请选择任务执行人', trigger: ['blur', 'change'] }
        ],
        planType: [
          { required: true, message: '请选择巡查类型', trigger: ['blur', 'change'] }
        ],
        patrolFrequency: [
          { required: true, message: '请输入巡查频次', trigger: ['blur', 'change'] }
        ],
        patrolMode: [
          { required: true, message: '请选择巡查方式', trigger: ['blur', 'change'] }
        ],
        offsetDistance: [
          { required: true, message: '请输入允许偏差距离', trigger: ['blur', 'change'] }
        ],
        generateType: [
          { required: true, message: '请选择生效方式', trigger: ['blur', 'change'] }
        ]
      },
      pickerOptions: {
        disabledDate: date => {
          return this.$dayjs(date).valueOf() < this.$dayjs().valueOf()
        }
      }
    }
  },
  computed: {
    projectList () {
      return this.$store.state.projectList || []
    },
    planId () {
      return this.$route.params.planId
    },
    dateName () {
      return this.formData.planType ? ['日', '周', '月'][this.formData.planType - 1] : ''
    }
  },
  created () {
    if (this.planId) {
      this.loadData()
    }
    this.loadSafeManageList()
  },
  methods: {
    planTypeChange (val) {
      if (val === 1) {
        this.formData.timeList = [{
          startTime: '',
          endTime: ''
        }]
      } else {
        this.formData.timeList = [{
          startTime: '00:00',
          endTime: '24:00'
        }]
      }
    },
    loadSafeManageList () {
      this.$axios.get(this.$apis.patrol.selectSafeManageList).then(res => {
        this.safeManageList = res || []
        if (this.safeManageList.length === 1) {
          this.formData.safeManageId = this.safeManageList[0].safeManageId
          this.safeManageChange(this.formData.safeManageId)
        }
      })
    },
    loadUserList (projectId, safeManageId) {
      this.$axios.get(this.$apis.patrol.selectUserList, {
        userType: '1',
        projectId,
        safeManageId
      }).then(res => {
        this.userList = res || []
        if (this.userList.length === 1) {
          this.formData.executeUserId = this.userList[0].userId
        }
      })
    },
    loadData () {
      this.$axios.get(this.$apis.patrol.selectPlaneByPlaneId, {
        planId: this.planId
      }).then(res => {
        this.formData = res || {}
      })
    },
    // 巡查方式切换
    patrolUnitChange () {
      this.patrolUnit = this.formData.patrolUnit
      this.formData.executeUserId = ''
      if (this.formData.projectId && this.formData.safeManageId) {
        this.loadUserList(this.formData.projectId, this.formData.safeManageId)
      }
    },
    // 项目切换
    projectChange (val) {
      if (val) {
        this.formData.executeUserId = ''
        this.formData.facilityIds = []
        this.formData.elementIds = []
        this.formData.lineList = []
        this.formData.safeManageId && this.loadUserList(val, this.formData.safeManageId)
      }
    },
    // 管理类型
    safeManageChange (val) {
      if (val) {
        this.formData.executeUserId = ''
        this.formData.projectId && this.loadUserList(this.formData.projectId, val)
      }
    },
    // 巡查类型切换
    patrolModeChange () {
      this.$refs.form.clearValidate(['lineList', 'facilityIds'])
    },
    // 日巡查 巡查频次变更
    frequencyChange () {
      const len = this.formData.timeList.length
      const val = Number(this.formData.patrolFrequency.toFixed(0))
      if (len > val) {
        this.formData.timeList.splice(val, len - val)
      } else {
        if (this.formData.planType === 1) {
          let i = len
          while (i < val) {
            this.formData.timeList.push({
              startTime: '',
              endTime: ''
            })
            i++
          }
        }
      }
    },
    validateProject () {
      if (!this.formData.projectId) {
        this.$message.warning('请先选择所属项目')
        return false
      } else {
        return true
      }
    },
    // 巡查路线选择
    onLineSelect () {
      if (this.validateProject()) {
        this.selectLineShow = true
      }
    },
    // 巡查对象选择
    onObjSelect () {
      if (this.validateProject()) {
        this.selectObjShow = true
      }
    },
    // 巡查点位选择
    onPointSelect () {
      if (this.validateProject()) {
        this.selectPointShow = true
      }
    },
    setElementIds (ids, list) {
      this.formData.elementIds = ids
      this.checkElementList = []
      this.checkElementListHandler(list)
    },
    checkElementListHandler (list) {
      list.forEach(item => {
        this.checkElementList.push({
          elementName: item.elementName,
          standard: item.standardList.map((x, i) => (i + 1) + '.' + x.standardContent).join(' ')
        })
      })
      this.$refs.form.clearValidate(['elementIds'])
    //   console.log('list: ', this.checkElementList)
    },
    checkFacilityListHandler (list) {
      list.forEach(item => {
        if (item.elementContent && item.elementContent.standardList.length) {
          item.elementContent = item.elementContent.standardList.map((x, i) => (i + 1) + '.' + x.standardContent).join(' ')
        } else {
          item.elementContent = ''
        }
      })
      // console.log('list: ', this.checkFacilityList)
      return list
    },
    setLineList (lineList, planeRegisterList) {
      this.formData.lineList = lineList
      this.formData.planeRegisterList = planeRegisterList
      this.$refs.form.clearValidate(['lineList'])
    },
    setFacilityIds (ids, list) {
      this.formData.facilityIds = []
      this.checkFacilityList = this.checkFacilityListHandler(list)
      //   console.log('拿到数据化', this.checkFacilityList)
      ids.forEach(item => {
        if (item) {
          this.formData.facilityIds.push(item)
        }
      })
      this.$refs.form.clearValidate(['facilityIds'])
    },
    submit () {
      this.$refs.form.validate().then(() => {
        this.disabled = true
        let data = {
          planName: this.formData.planName,
          patrolUnit: this.formData.patrolUnit,
          projectId: this.formData.projectId,
          safeManageId: this.formData.safeManageId,
          executeUserId: this.formData.executeUserId,
          planType: this.formData.planType,
          patrolFrequency: this.formData.patrolFrequency,
          patrolMode: this.formData.patrolMode,
          offsetDistance: this.formData.offsetDistance.toString(),
          generateType: this.formData.generateType,
          planeStopTime: this.formData.planeStopTime,
          timeList: [...this.formData.timeList]
        }
        // 如果是线路巡查，添加巡查要素及巡查路径， 如果是点位巡查，则添加设备列表
        if (data.patrolMode === 1) {
          data = {
            ...data,
            lineList: this.formData.lineList,
            elementIds: this.formData.elementIds,
            planeRegisterList: this.formData.planeRegisterList
          }
        } else {
          data = { ...data, facilityIds: this.formData.facilityIds }
        }
        this.$axios.post(this.$apis.patrol.insertPlane, data).then(() => {
          this.$message.success('新增成功')
          this.$router.push({ name: 'patrolTaskList' })
        }).finally(() => {
          this.disabled = false
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.selected-items {
    // margin-left: 140px;
    display: flex;
    padding: 10px;
    width: 504px;
    background: #F4F7FA;
    .label {
        min-width: 130px;
    }
    .items {
        /*margin-top: 10px;*/
        ::v-deep .el-tag {
            margin-right: 5px;
        }
    }
}
  .timeList {
    display: flex;
    align-items: center;
    ::v-deep .el-date-editor {
      width: 180px;
    }
  }
  ::v-deep .el-tag {
    margin-bottom: 5px;
  }
</style>
<style>
  .tool-tip {
    max-width: 400px;
  }
</style>
