<template>
  <el-dialog title="巡查对象选择" width="1200px" :visible="selectObjShow" :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="deviceBlock">
        <el-input
        placeholder="输入查询内容"
        v-model="filterText">
        </el-input>
        <div class="checkBlock">
          <el-checkbox :indeterminate="isCheckPark" v-model="checkAll" @change="checkAllChange">全选</el-checkbox>
          <div class="line"></div>
          <el-checkbox-group v-model="checkedIds" @change="handleCheckedChange">
            <el-checkbox v-for="item in elementList" :label="item.elementId" :key="item.elementId">{{item.elementName}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="form-container">
        <el-table height="100%" class="global-table" :data="tableData">
            <el-table-column :label="`已选巡查对象(${checkedIds.length})`" show-overflow-tooltip prop="elementName" min-width="50"></el-table-column>
            <el-table-column label="巡查要素" min-width="100">
                <template v-slot="{ row }">
                    <p style="width: 100%; word-break: break-all; word-wrap: break-word;" v-for="(item, index) in row.standardList" :key="index">{{index+1}}.{{item.standardContent}}</p>
                </template>
            </el-table-column>
            <el-table-column label="建议巡查频次" show-overflow-tooltip min-width="50">
                <template v-slot="{ row }">
                    {{filtFrequency(row)}}
                </template>"
            </el-table-column>
            <el-table-column label="应急处置建议" prop="emergencyDisposal" min-width="100"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="onSave">确认</el-button>
      <el-button type="info" @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ObjSelect',
  props: {
    projectId: String,
    selectObjShow: Boolean,
    patrolUnit: Number,
    elementIds: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    tableData () {
      return this.elementList.filter(item => this.checkedIds.includes(item.elementId))
    }
  },
  watch: {
    selectObjShow (val) {
      if (val) {
        this.init()
      }
    },
    filterText (val) {
      this.elementList = this.elementListBak.filter(item => item.elementName.indexOf(val) >= 0)
    }
  },
  data () {
    return {
      filterText: '',
      elementListBak: [],
      elementList: [],
      isCheckPark: false,
      checkAll: false,
      checkedIds: [],
      eIds: []
    }
  },
  created () {
    this.checkedIds = this.elementIds
  },
  methods: {
    filtFrequency (val) {
      let timeTxt = ''
      switch (val.corpFrequencyType) {
        case 1:
          timeTxt = '日'
          break
        case 2:
          timeTxt = '周'
          break
        default:
          timeTxt = '月'
          break
      }
      if (this.patrolUnit === 1) {
        return val.corpFrequency + '次/' + timeTxt
      } else {
        return val.cooperateFrequency + '次/' + timeTxt
      }
    },
    init () {
      this.filterText = ''
      this.elementListBak = []
      this.isCheckPark = false
      this.checkAll = false
      this.eIds = []
      this.loadElementList()
    //   console.log('表格数据', this.tableData)
    },
    loadElementList () {
      this.$axios.get(this.$apis.patrol.selectElementByList).then(res => {
        // console.log('巡查对象列表', res.list)
        this.elementList = res.list || []
        this.elementListBak = [...this.elementList]
        this.elementList.forEach(item => {
          this.eIds.push(item.elementId)
        })
        const checkLen = this.elementIds.length
        if (checkLen > 0 && checkLen < this.eIds.length) {
          this.isCheckPark = true
          this.checkAll = false
          this.checkedIds = [...this.elementIds]
        } else {
          this.isCheckPark = false
          this.checkAll = this.eIds.length > 0
          this.checkedIds = [...this.eIds]
        }
      })
    },
    checkAllChange (val) {
      if (val) {
        this.checkedIds = [...this.eIds]
      } else {
        this.checkedIds = []
        // this.tableData = []
      }
      this.isCheckPark = false
    },
    handleCheckedChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.eIds.length
      this.isCheckPark = checkedCount > 0 && checkedCount < this.eIds.length
    },
    onSave () {
      if (!this.checkedIds.length) {
        this.$message.warning('请至少选择一个巡查对象')
      } else {
        const checkElementList = []
        this.checkedIds.forEach((item, index) => {
          this.elementList.forEach(x => {
            if (x.elementId === item) {
              checkElementList.push({
                elementName: x.elementName,
                standardList: x.standardList
              })
            }
          })
        })
        this.$emit('setElementIds', this.checkedIds, checkElementList)
        this.closeDialog()
      }
    },
    // 关闭
    closeDialog () {
      this.$emit('update:selectObjShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .text-center {
    text-align: center;
  }
  .content {
    display: flex;
    position: relative;
    height: 600px;
    .deviceBlock {
        width: 200px;
        .el-input {
          width: 100%;
          margin-bottom: 16px;
        }
        .checkBlock {
          width: 200px;
          height: 100%;
          overflow: auto;
          background-color: #fff;
          .line {
            border-top: 1px solid #EBEBEB;
          }
          .el-checkbox {
            padding: 12px 20px;
          }
          .el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 20px;
            max-height: 470px;
            // overflow: auto;
            .el-checkbox {
              width: 300px;
              padding: 8px 0;
            }
          }
        }
      }
    .form-container {
        flex: 1;
        margin-left: 20px;
        height: 100%;
        overflow: auto;
        .element {
            display: inline-block;
            width: 100%;
            word-break: break-all;
            word-wrap: break-word;
        }
    }
    .buttonBlock {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      justify-content: right;
    }
  }
</style>
