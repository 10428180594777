<template>
  <el-dialog title="巡查线路绘制" width="1200px" :visible="selectLineShow" :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="mapBlock" id="map"></div>
      <div class="buttonBlock">
        <div class="draw" :class="{ active: isDrawing }" @click="drawPatrol">绘制</div>
        <div class="delete" @click="deletePatrol">删除</div>
      </div>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="onSave">确认</el-button>
      <el-button type="info" @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '../../../utils/config'
import { drawPolygon } from '../../../utils/formatData'
const patrolSign = require('@/assets/img/patrol/sign.svg')
const patrolNotpassed = require('@/assets/img/patrol/notpassed.svg')
export default {
  name: 'LineSelect',
  props: {
    projectId: String,
    selectLineShow: Boolean,
    lineList: {
      type: Array,
      default: () => {
        return []
      }
    },
    planeRegisterList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
  },
  watch: {
    selectLineShow (val) {
      if (val) {
        this.init()
      }
    }
  },
  data () {
    return {
      pointList: [], // 必经点
      label: [], // 覆盖物的文字标签
      lines: [],
      isDrawing: false
    }
  },
  created () {
    // ....
  },
  methods: {
    init () {
      this.lines = [...this.lineList]
      this.pointList = [...this.planeRegisterList]
      this.isDrawing = false
      this.getProjectLocation()
    },
    getProjectLocation () {
      this.$axios.get(this.$apis.project.selectProjectAreaById, {
        projectId: this.projectId
      }).then((res) => {
        this.$nextTick(() => {
          this.loadMap(res.projectInfo, res.projectLocation)
        })
      })
    },
    loadMap (projectInfo, list) {
      const center = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      if (!this._map) {
        AMapLoader.load({
          key: mapKey,
          version: '2.0',
          plugins: ['AMap.MouseTool']
        }).then(() => {
          const map = new AMap.Map('map', {
            center: center,
            zoom: 16
          })
          this._map = map
          this.mapInit(list)
        })
      } else {
        this._map.clearMap()
        this._map.setZoomAndCenter(16, center)
        this.mapInit(list)
      }
    },
    mapInit (list) {
      this.ploygons = drawPolygon(this._map, list || [], { zIndex: 10 }, null)
      if (this.lines.length > 0) {
        // 巡查路线
        const lngLat = this.lines.map(item => {
          return new AMap.LngLat(item.longitude, item.latitude)
        })
        this.drawPatrolPolyline(lngLat)
        const markers = [{
          ...this.lines[0]
        }, {
          ...this.lines[this.lines.length - 1]
        }]
        this.drawMarkers(markers)
        this.drawMarkers(this.pointList, true)
      }
    },
    drawPatrolPolyline (lngLat) {
      // 创建面对象
      const polyline = new AMap.Polyline({
        path: lngLat,
        strokeWeight: 4,
        strokeColor: '#FF9B00',
        strokeOpacity: 1
      })
      this._map && this._map.add(polyline)
    },
    drawMarkers (list, isMustPoint) {
      list.forEach((item) => {
        if (item.longitude && item.latitude) {
          let marker = null
          const lngLat = new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          if (isMustPoint) {
            marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: patrolNotpassed
              }),
              size: new AMap.Pixel(44, 49),
              offset: new AMap.Pixel(-22, -38),
              position: lngLat,
              label: {
                direction: 'top',
                content: '必经点'
              }
            })
          } else {
            marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: patrolSign
              }),
              size: new AMap.Pixel(24, 24),
              offset: new AMap.Pixel(-12, -24),
              position: lngLat
            })
          }
          this._map && this._map.add(marker)
        }
      })
    },
    // 画巡查路线
    drawPatrol () {
      if (this.lines.length) {
        this.$message.warning('您已经有巡查线路了，请先删除')
      } else {
        this.isDrawing = !this.isDrawing
        if (this.isDrawing) {
          this.createLine()
        } else {
          this.lineToolClose()
        }
      }
    },
    // 绘制线条
    createLine () {
      this.lineToolClose()
      const config = {
        strokeWeight: 4,
        strokeColor: '#FF9B00',
        strokeOpacity: 1
      }
      this._lineTool = new AMap.MouseTool(this._map)
      this._lineTool.polyline(config)
      this._lineTool.on('draw', this.drawFinishLine)
    },
    // 关闭线条绘制状态
    lineToolClose () {
      if (this._lineTool) {
        this._lineTool.close()
        this._lineTool = null
      }
    },
    // 双击保存已绘制的文件
    drawFinishLine (e) {
      const data = e.obj.$x[0].map(item => {
        return {
          latitude: item[1],
          longitude: item[0]
        }
      })
      this.lines = [...data]
      this.getMustPoints(data)
      const markers = [{
        ...this.lines[0]
      }, {
        ...this.lines[this.lines.length - 1]
      }]
      this.drawMarkers(markers)
      this.lineToolClose()
      this.isDrawing = false
    },
    getDistance (lng1, lat1, lng2, lat2) {
      const p1 = new AMap.LngLat(lng1, lat1)
      const p2 = new AMap.LngLat(lng2, lat2)
      const distance = Math.round(p1.distance(p2))
      return distance
    },
    getMustPoints (data) {
      let distance = 0
      for (let i = 0; i < data.length - 1; i++) {
        const nowItem = data[i]
        const nextItem = data[i + 1]
        distance += this.getDistance(nowItem.longitude, nowItem.latitude, nextItem.longitude, nextItem.latitude)
      }
      //   console.log('总距离', distance)
      const points1 = this.getMustPoint(data, distance, distance / 3)
      const points2 = this.getMustPoint(data, distance, distance / 3 * 2)
      //   console.log('两点', points1, points2)
      this.pointList = [points1, points2]
      this.drawMarkers(this.pointList, true)
    },
    getMustPoint (data, distance, mustDistance) {
      let currentDistance = 0
      for (let i = 0; i < data.length - 1; i++) {
        const nowItem = data[i]
        const nextItem = data[i + 1]
        const current = this.getDistance(nowItem.longitude, nowItem.latitude, nextItem.longitude, nextItem.latitude)
        currentDistance += current
        if (currentDistance > mustDistance) {
          const percent = (currentDistance - mustDistance) / current
          const longitude = (+nextItem.longitude - +nowItem.longitude) * (1 - percent) + +nowItem.longitude
          const latitude = (+nextItem.latitude - +nowItem.latitude) * (1 - percent) + +nowItem.latitude
          return {
            longitude,
            latitude
          }
        }
      }
    },
    deletePatrol () {
      if (this.lines.length) {
        this.$confirm('确认删除巡查线路吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const polyline = this._map.getAllOverlays('polyline')
          this._map.remove(polyline)
          const markers = this._map.getAllOverlays('marker')
          this._map.remove(markers)
          this.lines = []
          this.pointList = []
        })
      } else {
        this.$message.warning('没有可删除的巡查线路')
      }
    },
    onSave () {
      if (!this.lines.length) {
        this.$message.warning('请绘制巡查线路')
      } else {
        this.pointList.forEach(item => {
          delete item.isMustPoint
        })
        this.$emit('setLineList', this.lines, this.pointList)
        this.closeDialog()
      }
    },
    // 关闭
    closeDialog () {
      this.$emit('update:selectLineShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .text-center {
    text-align: center;
  }
  .content {
    position: relative;
    width: 100%;
    height: 600px;
    .mapBlock {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .buttonBlock {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      justify-content: right;
      .draw, .delete {
        width: auto;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3592FE;
        line-height: 22px;
        cursor: pointer;
        padding: 5px 18px;
        background: #FFFFFF;
        border: 1px solid #3592FE;
        &:not(:first-child) {
          margin-left: 16px;
        }
        &.delete {
          color: #FF402B;
          border-color: #FF402B;
        }
        &.draw.active {
          color: #ffffff;
          background: #3592FE;
        }
      }
    }
  }
  ::v-deep .amap-marker-label {
    border-color: gray;
  }
</style>
